import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Empresa from './Empresa';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from './Material/PaginatedSettingJobPlace';
import {filter, includes, cloneDeep, findIndex} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import {GetRole, GetEmpresa} from '../helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import DatePicker from './DatePicker';
import { IMe, IRegister, IIRegister, ICentroTrabajo, IJobplace, IFilter, IIJobplace, IOptionRow } from '../types';
import LoaderBeauty from './Material/activityProgress';
import CreateModal from './CreateDialog';
import List from './Material/MultiSelect';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import ReactExport from 'react-export-excel';
import SearchField from './SearchField';
import LateralMenu from './LateralMenu';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import {episGroup} from '../ApiClients/DummyData';
import {
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/DeleteForever';

import classnames from "classnames";
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';


function tmRegister(centrotrabajoId: any){
    return ({
        centrotrabajoId:centrotrabajoId,
        id: -1,
        description: '',
        activated: true,
        used: false,
        episSet: false,
        episComments: "",
        epis: []
    });
};

const options_filters:any = {
    used: [
        {
            id: "1",
            name: "SÍ",
        },
        {
            id: "0",
            name: "NO",
        }
    ]
}

const exportDataExcel=(empresa:ICentroTrabajo, data:Array<IJobplace>)=>{
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const d = new Date();
    const date = `${d.getFullYear()}${String(d.getMonth()+1).padStart(2,'0')}${String(d.getDate()).padStart(2,'0')}`;
    
    data = data.map((item:IJobplace, idx:number)=>{
        return({
            ...item,
            option: `${item.id}_${item.description}`
        })
    })

    let sheets = 
            <ExcelSheet data={data} name={`PuestosTrabajo`} key={0}>
                <ExcelColumn label="id" value="id"/>
                <ExcelColumn label="dsc" value="description"/>
                <ExcelColumn label="option" value="option"/>
            </ExcelSheet>

    return (
        <ExcelFile filename={`PuestosTrabajo_${empresa.description.replaceAll(' ','_')}_${date}`} hideElement={true} element={<button>{`Descargar`}</button>}>
            {sheets}
        </ExcelFile>
    );
}

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fieldsUsers = [
    [
        {
            id: 'description',
            name: 'Descripción',
            required: true,
            style:3,
            helper:'',
            maxText:200,
            typeField:2,
            multiline:false
        },
        {
            id: 'activated',
            name: 'Activo',
            required: true,
            style:1,
            typeField:5,
        }
    ],
];

const fields_filters = [
    {
        id: 'used',
        name: 'Configurado',
        required: false,
        style:1,
        type: 1
    }                  

];

interface IFormListadoItemProps {
    classes: any;
    options:any;
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    //centrotrabajoId:string;
    register: IJobplace;
}

interface IFormListadoItemState {
    itemListado:any;
}

class FormListadoItem extends Component<IFormListadoItemProps, IFormListadoItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            itemListado: props.register,
        };
    }

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }    
    
    handleChangeCheckBox=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.checked;
        this.setState({ itemListado: newItemListado });
    }    
    

    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    handleListChange=(id:any, e:any, list:any)=> {
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = list;
        this.setState({ itemListado: newItemListado });
    }

    verifyForm=(type:number, id?:string)=>{

        if(type == 1){ // Verifica todo el formulario
            const item = this.state.itemListado;
            if(item.role == -1){
                return false;
            } else if(item.description.length == 0){
                return false;
            }

            return true;
        } else if(type == 2){ // Verifica el ítem

        }

        return false;
        /*let idProduct : number = Number(this.state.productInfo.id)
        let ivaProduct : number = Number(this.state.productInfo.iva)

        if(verificationType == 0) {
            switch(id){
                case "id":
                    if(idProduct <= 0){
                        return false;
                    }
                    break;
                case "name":
                    if(this.state.productInfo.name.length == 0){
                        return false;
                    }
                    break;
                case "iva":
                    if(ivaProduct < 0){
                        return false;
                    }
                    break;
            }
            return true;
        } else {

            if(idProduct <= 0){
                return false;
            }

            if(this.state.productInfo.name.length == 0){
                return false;
            }

            if(ivaProduct < 0){
                return false;
            }
            return true;
        }*/

    }
    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    render() {
        const {itemListado} = this.state;

        let fieldsTMP = fieldsUsers.map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 1:
                        return(
                            <SelectorField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.props.options[n.id]?this.props.options[n.id]:[]}
                                keyDescription={n.id}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                required={n.required}
                                style={n.style}
                                deviceData={itemListado}
                            />
                        );
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeUser}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={true}
                                multiline={n.multiline}
                            />
                        );
                    case 3:
                        return(
                            DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, itemListado.fechaReferencia, false, this.shouldDisableToday, new Date('1970-01-01'))
                        );
                    case 4:
                        return(
                            <div style={{marginBottom:20}}>
                                <List id={n.id} data={itemListado[n.id]} multiple={true} name={n.name} placeholder={n.name} options={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.activated}):[]} handleChangeSelect={(e:any, list:any)=>this.handleListChange(n.id,e,list)}/>
                            </div>
                        );
                    case 5:
                        return(
                            <CheckBoxField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={this.handleChangeCheckBox}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                            />
                        )
                    default:
                        return('');
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                <DialogTitle id="form-dialog-title">{'FORMULARIO PUESTO TRABAJO'}</DialogTitle>
                <DialogContent>
                    {fieldsTMP}
                </DialogContent>
                <DialogActions>
                    {!this.props.loading?
                        <div style={{ display:'flex', gap:20 }}>
                            <Button onClick={()=>this.props.handleCloseItemForm()} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(1)} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className="confirm-dialog">
                                {`${itemListado.id == -1?`Crear`:`Actualizar`}`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}
const FormListado = withStyles(listadoStyles)(FormListadoItem);

interface IFormSettingEpisItemProps {
    classes: any;
    options:any;
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    //centrotrabajoId:string;
    register: IJobplace;
}

interface IFormSettingEpisItemState {
    itemListado:any;
    anchorEl:any|null;
    popupconfirmOpen:boolean;
    popupEpiContent: any;
}

class FormSettingEpisItem extends Component<IFormSettingEpisItemProps, IFormSettingEpisItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            itemListado: props.register,
            anchorEl: null,
            popupconfirmOpen:false,
            popupEpiContent:'',
        };
    }

    handleOpenList = (ev:any) => {
        this.setState({
            anchorEl: ev.currentTarget
        })
    }

    handleCloseList = () => {
        this.setState({
            anchorEl: null
        })
    }

    handleConfirmSelectSubection = (action:number, subsection?: IIJobplace) => {
        let tmp = cloneDeep(this.state.itemListado);

        if(subsection)
            tmp.epis.push(subsection);
        else
            tmp.epis.push(this.state.anchorEl);
        
        tmp.epis = tmp.epis.map((item:any, idx:number)=>{
            return {
                ...item,
                id: idx
            }
        })
        this.setState({
            itemListado: tmp
        }, ()=>this.handleClosePopupConfirm())
    };

    handleClosePopupConfirm = () =>{
        this.setState({
            popupconfirmOpen: false,
            anchorEl: null,
            popupEpiContent: ''
        })
    }

    handleSelectSubection = (subsection: IIJobplace) => {
        // let tmp = cloneDeep(this.state.itemListado);
        // console.log(tmp)

        // tmp.epis.push(subsection);

        // tmp.epis = tmp.epis.map((item:any, idx:number)=>{
        //     return {
        //         ...item,
        //         id: idx
        //     }
        // })
        // this.setState({
        //     itemListado: tmp
        // }, ()=>this.handleCloseList())        
        let popupEpi = 
            <div style={{ display:'flex', gap:'2em', alignItems:'center' }}>
                <div className='jobplace-epis-icon-popup'>
                    <img src={subsection.group_icon}/>
                </div>
                <div>
                    <h3 className='jobplace-epi-detail'>
                        {subsection.name.toLocaleLowerCase()}
                    </h3>
                    <div className='jobplace-epi-detail' style={{ whiteSpace:'pre-wrap' }}>
                        {subsection.detail}
                    </div>
                </div>
            </div>;
        this.setState({
            popupconfirmOpen: true,
            anchorEl: subsection,
            popupEpiContent: popupEpi,
        })
    };

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }    
    
    handleChangeUserEpisGroup=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }    

    handleChangeCheckBox=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.checked;
        this.setState({ itemListado: newItemListado });
    }    
    

    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    handleListChange=(id:any, e:any, list:any)=> {
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = list;
        this.setState({ itemListado: newItemListado });
    }

    verifyForm=(type:number, id?:string)=>{

        if(type == 1){ // Verifica todo el formulario
            const item = this.state.itemListado;
            if(!item.episSet && item.epis.length == 0){
                return false;
            }

            return true;
        } else if(type == 2){ // Verifica el ítem

        }

        return false;
        /*let idProduct : number = Number(this.state.productInfo.id)
        let ivaProduct : number = Number(this.state.productInfo.iva)

        if(verificationType == 0) {
            switch(id){
                case "id":
                    if(idProduct <= 0){
                        return false;
                    }
                    break;
                case "name":
                    if(this.state.productInfo.name.length == 0){
                        return false;
                    }
                    break;
                case "iva":
                    if(ivaProduct < 0){
                        return false;
                    }
                    break;
            }
            return true;
        } else {

            if(idProduct <= 0){
                return false;
            }

            if(this.state.productInfo.name.length == 0){
                return false;
            }

            if(ivaProduct < 0){
                return false;
            }
            return true;
        }*/

    }
    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    handleDeleteEpisGroup = (idx:number) => {
        let tmp = cloneDeep(this.state.itemListado);
        console.log(tmp)

        tmp.epis.splice(idx,1);
        this.setState({
            itemListado: tmp
        })
    }

    handleDataChangeM01 = (evt:any, i:number) => {
        let nregister = cloneDeep(this.state.itemListado);

        nregister.epis[i][evt.target.id] = evt.target.value;

        this.setState({itemListado:nregister});
    }

    render() {
        const {itemListado} = this.state;
        // console.log(itemListado)

        let episOptions = episGroup.map((epi:IIJobplace, idx00:number)=>{
            return (
                <Tab component={() => (
                    <div className='jobplace-epis-icon' onClick={epi.detail.length == 0?()=>this.handleConfirmSelectSubection(0, epi):()=>this.handleSelectSubection(epi)}>
                        <img src={epi.group_icon} alt={epi.name}/>
                        <div className="jobplace-epis-icon-info">{epi.name}</div>
                    </div>
                )} />
            )
        })

        let fieldsTMP =
            <form key={0} noValidate autoComplete="off">
                <TextFieldInput
                    key={0}
                    name={'Puesto de trabajo'}
                    id={'description'}
                    fieldsData={itemListado}
                    handleFieldChange={this.handleChangeUser}
                    disabled={true}
                    size="medium"
                    shrink={true}
                    //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                    //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                    type={'text'}
                    required={true}
                    style={3}
                    helper={''}
                    maxText={1000}
                    //noError={n.required?this.verifyForm(0,n.id):true}
                    noError={true}
                    multiline={false}
                />

                <TextFieldInput
                    key={0}
                    name={'Comentarios'}
                    id={'episComments'}
                    fieldsData={itemListado}
                    handleFieldChange={this.handleChangeUserEpisGroup}
                    disabled={false}
                    size="medium"
                    shrink={true}
                    //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                    //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                    type={'text'}
                    required={false}
                    style={3}
                    helper={''}
                    maxText={2000}
                    //noError={n.required?this.verifyForm(0,n.id):true}
                    noError={true}
                    multiline={false}
                />
                <Tabs className='jobplace-epis-container'
                    value={0}
                    variant="scrollable"                    
                    allowScrollButtonsMobile        
                >
                    {episOptions}
                </Tabs>
                {/* <div className='jobplace-epis-container'>
                    {episOptions}
                </div> */}
                {/* <React.Fragment>
                    <Button
                        variant="contained"
                        className={"addSectionButton"}
                        onClick={(e) => this.handleOpenList(e)}
                        disabled={
                            !Array.isArray(episGroup) || !episGroup.length
                        }
                    >
                    <Add />
                    <Typography variant="subtitle2" color="inherit">
                        {`Nuevo grupo EPIs`}
                    </Typography>
                    </Button>
                    <Menu
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={()=>this.handleCloseList}
                        keepMounted
                        // className={classnames(classesByKey("subsectionMenu"))}
                    >
                    {episGroup.map((s, idx:number) => (
                        <MenuItem
                            key={idx}
                            // className={classnames(classesByKey("subsectionMenuItem"))}
                            onClick={() => this.handleSelectSubection(s)}
                        >
                            {s.id}. {s.name}
                        </MenuItem>
                    ))}
                    </Menu>
                </React.Fragment> */}

                <Paper style={{width:'100%', overflowX:'auto'}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><strong>{`Nº`}</strong></TableCell>
                                <TableCell align="center"><strong>{`EQUIPO`}</strong></TableCell>
                                <TableCell align="center"><strong>{`Uds`}</strong></TableCell>
                                <TableCell style={{whiteSpace:'nowrap'}} align="center"><strong>{`MARCA / MODELO CATEGORÍA Y CLASE`}</strong></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.itemListado.epis.map((item:IIJobplace, idx:number)=>{
                                return(                                    
                                    <TableRow className={this.props.classes.row} key={idx}> 
                                        <TableCell  align="center">{`${idx+1}`}</TableCell>
                                        <TableCell  align="center">{`${item.name}`}</TableCell>
                                        <TableCell style={{width:'20px'}}  align="center">{<input type="number" style={{fontSize:16,width: "4em"}} id={`ud`} value={item.ud} onChange={(evt:any)=>this.handleDataChangeM01(evt, idx)}></input>}</TableCell>
                                        <TableCell  align="center">{<textarea rows={2} style={{fontSize:16, maxWidth:250, width:'100%'}} id={`description`} value={item.description} onChange={(evt:any)=>this.handleDataChangeM01(evt, idx)}></textarea>}</TableCell>
                                        <TableCell  align="center">
                                            <IconButton onClick={()=>this.handleDeleteEpisGroup(idx)} aria-label="delete user">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow> 
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </form>

        // let fieldsTMP = fieldsSettingEPIs.map((n0:any, index:number)=>{
        //     let fieldsN0 = n0.map((n:any, index:number)=>{
        //         switch(n.typeField){
        //             case 1:
        //                 return(
        //                     <SelectorField
        //                         key={index}
        //                         name={n.name}
        //                         id={n.id}
        //                         fieldsData={this.props.options[n.id]?this.props.options[n.id]:[]}
        //                         keyDescription={n.id}
        //                         handleFieldChange={this.handleChangeUser}
        //                         //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
        //                         required={n.required}
        //                         style={n.style}
        //                         deviceData={itemListado}
        //                     />
        //                 );
        //             case 2:
        //                 return(
        //                     <TextFieldInput
        //                         key={index}
        //                         name={n.name}
        //                         id={n.id}
        //                         fieldsData={itemListado.episGroup}
        //                         handleFieldChange={this.handleChangeUser}
        //                         disabled={false}
        //                         size="medium"
        //                         shrink={true}
        //                         //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
        //                         //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
        //                         type={n.type}
        //                         required={n.required}
        //                         style={n.style}
        //                         helper={n.helper}
        //                         maxText={n.maxText}
        //                         //noError={n.required?this.verifyForm(0,n.id):true}
        //                         noError={true}
        //                         multiline={n.multiline}
        //                     />
        //                 );
        //             case 3:
        //                 return(
        //                     DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, itemListado.fechaReferencia, false, this.shouldDisableToday, new Date('1970-01-01'))
        //                 );
        //             case 4:
        //                 return(
        //                     <div style={{marginBottom:20}}>
        //                         <List id={n.id} data={itemListado[n.id]} multiple={true} name={n.name} placeholder={n.name} options={this.props.options[n.id]?filter(this.props.options[n.id], function(o){return o.activated}):[]} handleChangeSelect={(e:any, list:any)=>this.handleListChange(n.id,e,list)}/>
        //                     </div>
        //                 );
        //             case 5:
        //                 return(
        //                     <CheckBoxField
        //                         key={index}
        //                         name={n.name}
        //                         id={n.id}
        //                         fieldsData={itemListado}
        //                         handleFieldChange={this.handleChangeCheckBox}
        //                         disabled={false}
        //                         size="medium"
        //                         shrink={true}
        //                         //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
        //                         //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
        //                         type={n.type}
        //                         //noError={n.required?this.verifyForm(0,n.id):true}
        //                     />
        //                 )
        //             default:
        //                 return('');
        //         }
        //     })
        //     return (

        //     )
        // })

        

        return (
            <div>
                <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                    <DialogTitle id="form-dialog-title">{'CONFIGURACIÓN EPIS'}</DialogTitle>
                    <DialogContent>
                        {fieldsTMP}
                    </DialogContent>
                    <DialogActions>
                        {!this.props.loading?
                            <div style={{ display:'flex', gap:20 }}>
                                <Button onClick={()=>this.props.handleCloseItemForm()} className="cancel-dialog">
                                    {'Cancelar'}
                                </Button>
                                <Button disabled={!this.verifyForm(1)} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className={`confirm-dialog ${!this.verifyForm(1)?`confirm-dialog-disable`:``}`}>
                                    {`${!itemListado.episSet?`Crear`:`Actualizar`}`}
                                </Button>
                            </div>
                        :
                            <Loader loading={true}/>
                        }
                    </DialogActions>
                </Dialog>
                {this.state.popupconfirmOpen?
                    <CreateModal
                        onCloseCreationModal={this.handleClosePopupConfirm}
                        isOpen={this.state.popupconfirmOpen}
                        inputText={this.state.popupEpiContent}
                        typeModal={2}
                        action={this.handleConfirmSelectSubection}
                        typeAction={-1}
                        loadingModal={false}
                        titleModal={''}
                    />
                :
                    null
                }
            </div>
            
            
        );
    }
}
const FormSettingEpis = withStyles(listadoStyles)(FormSettingEpisItem);

interface IListadoProps {
    history:any;
    match:any;
    me?: IMe;
    version: string;
    filters: IFilter;
    handleChangeFilter: Function;
    handleCheckFilter: Function;
}

interface IListadoState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingDocuments:boolean;
    documents:any;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    bRegister: IJobplace;
    loadingModal: boolean;
    titleModal: string;
    editing: boolean;
    saving: boolean;
    downloaddata:boolean;

    filter_id: string;

    creationEpisOpen: boolean;
}

class Income extends Component<IListadoProps, IListadoState> {
    constructor(props: IListadoProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            loadingDocuments: true,
            searchValue: '',
            creatingItem: false,
            bRegister: tmRegister(this.props.match.params.centrotrabajoId),
            loadingModal: false,
            titleModal: `MENSAJE`,
            editing: false,
            saving: false,
            downloaddata:false,
            filter_id: "f_sj",
            creationEpisOpen: false
        };
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(prevProps:IListadoProps){
        if(prevProps.me !== this.props.me)
            this.loadData();
    }

    loadData = () => {
        const {me} = this.props;

        if(!me)
            return;
        if ([20, 50, 100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) > -1) {
            this.props.handleCheckFilter(this.props.match.params.centrotrabajoId);
            this.loadWorkplace();
        } else {
            this.setState({
                loading:false,
                loadingDocuments: false
            })
        }
    }

    loadWorkplace = async () => {
        const centrotrabajoId = Number(this.props.match.params.centrotrabajoId);
        let response = await OrderService.LoadJobPlace(centrotrabajoId);
        this.setState({loadingDocuments: false, documents: response.jobplace, options: {}});
    }

    
    handleFilterDocuments=(documents:Array<IJobplace>, f:any)=>{
        let newDocuments = cloneDeep(documents);
        
        //** FILTRA POR LOS FILTROS APLICADOS */
        const keys = Object.keys(f);
        const values:any = Object.values(f);

        if(keys.length > 0){
            for(let a=0; a<keys.length;a++){
                newDocuments = filter(newDocuments, function(o){return o[keys[a]] == values[a]})
            }
        }

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.description.toLowerCase(), searchValue) });
        }

        return newDocuments;
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false, });
    }

    onCloseFormItemModal = () => {
        this.setState({ creationItemOpen: false });
    }

    
    onCloseFormEpisItemModal = () => {
        this.setState({ creationEpisOpen: false });
    }


    /* CALL BACKEND */


    /* */

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1:
                break;
        }
        this.onCloseCreationModal();
    }

    onCloseCreationModalEmail = () => {
        this.setState({ creationModalOpenEmail: false });
    }

    handleClickListado=(income:number)=>{
        this.setState({creationItemOpen:true, bRegister: tmRegister(this.props.match.params.centrotrabajoId)});
        /*switch(income){
            case 1:
                this.props.history.push('/generar/preventivo')
                break;
            case 2:
                this.props.history.push('/ingressos/correctivo')
                break;
        }*/
    }

    handleSelectYearChange = (event:any) => {
    }

    handleItemClick=(item:any)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters[this.state.filter_id]);
        newFilters[id] = evt.target.value;

        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);

    }

    handleCreateItem=(item:any)=>{
        this.setState({creatingItem:true})
        this.saveData(item, 12);
    }

    
    handleCreateEpisItem=(item:any)=>{
        this.setState({creatingItem:true})
        // console.log(item)
        this.saveData(item, 19);
    }

    saveData=async(item:any, action: number)=>{
        let response:any = await OrderService.saveData(item, action);
        if(response == 2){
            this.setState({
                modalText: '¡ATENCIÓN! Ya existe un puesto de trabajo igual.',
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
            });
        } else if(response == 3){


            this.setState({
                modalText: `¡ATENCIÓN! No se puede desactivar el puesto de trabajo "${item.description}" ya que se encuentra configurado en el panel de control de este u otros centros de trabajo.`,
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
                creationItemOpen: false,
            });
        } else {
            this.loadWorkplace();
            this.setState({creatingItem:false, creationItemOpen:false, creationEpisOpen: false});
        }
    }

    saveData02 = async (documents:Array<IJobplace>) => {
        const changed_documents = filter(documents, function(o){return o.changed});

        let response:any = await OrderService.saveData({jobplaces:changed_documents}, 13);

        if(response !== 1){
            this.setState({
                modalText: '¡ATENCIÓN! No se han podido guardar los cambios.',
                modalType: 1,
                creationModalOpen: true,
                creatingItem:false,
                saving: false
            });
        } else {
            this.loadWorkplace();
            this.setState({creatingItem:false, creationItemOpen:false, saving: false, editing: false});
        }
    }

    handleSaveData = (documents:Array<IJobplace>) => {
        this.setState({
            saving: true,
        }, ()=>this.saveData02(documents));
    }

    saveData03 = async (jobplace:IJobplace) => {
        let response:any = await OrderService.saveData(jobplace, 13);

        if(response !== 1){
            let newItemListado = cloneDeep(this.state.documents);
            const idx = findIndex(newItemListado, function(o:IJobplace){return o.id == jobplace.id});
    
            if(idx > -1)
                newItemListado[idx].used = !jobplace.used;

            if(response == 3){
                let response:any = await OrderService.LoadPanelControl(this.props.match.params.centrotrabajoId);
                let count = undefined;
                let modal_text:any = `¡ATENCIÓN! No se puede desactivar el puesto de trabajo "${jobplace.description}" para este centro de trabajo porqué existen otras OTs con dicho puesto de trabajo configurado.`
    
                /*if(response){
                    count = filter(response, function(o){return o.seccion == section.id});
                    modal_text = <div><p>{`¡ATENCIÓN! No se puede desactivar la sección "${section.description}" para este centro de trabajo porqué existen las siguientes OTs con dicha sección configurada:`}</p><p style={{fontWeight:'bold'}}>{count.map(function(o){return `OT: ${o.id}`}).join("\r\n")}</p></div>
                }*/
    
                this.setState({
                    modalText: modal_text,
                    modalType: 1,
                    creationModalOpen: true,
                    creatingItem:false,
                    documents: newItemListado,
                    loadingDocuments: false
                });
            } else { 
                this.setState({
                    modalText: '¡ATENCIÓN! No se ha podido guardar el cambio.',
                    modalType: 1,
                    creationModalOpen: true,
                    creatingItem:false,
                    documents: newItemListado,
                    loadingDocuments: false
                });
            }
        }
        else {
            this.setState({
                creationModalOpen: false,
                creatingItem:false,
                loadingDocuments: false
            });
        }
    }

    handleClickOptionRow=(register:IJobplace, option:number)=>{
        switch(option){
            case 1: // Implica editar el registro
                this.setState({creationItemOpen:true, bRegister: register})
                break;
            case 2: // Implica editar los EPIss
                this.setState({creationEpisOpen:true, bRegister: register})

                break;
            default:
                break;
        }
    }
        
    handleChangeCheckBox=(id:any, event:any, register:IJobplace)=>{
        let newItemListado = cloneDeep(this.state.documents);
        const idx = findIndex(newItemListado, function(o:IJobplace){return o.id == register.id});

        if(idx > -1){
            newItemListado[idx][id] = event.target.checked;
            newItemListado[idx].changed = true;
            this.setState({ documents: newItemListado, loadingDocuments:true, /*editing: true*/ }, ()=>this.saveData03(newItemListado[idx]));
        }
    }    

    handleDownloadList = (data:Array<any>) => {
        const f_data = filter(data, function(o){return o.used});

        if(f_data.length == 0)
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No hay ningún registro para exportar para este centro de trabajo.`,
                modalType: 1,
                creationModalOpen: true
            })
        else
            this.setState({
                downloaddata: true
            }, ()=>setTimeout(() => {
                this.setState({downloaddata: false})
            }, 2500))

    }

    RenderFilters = (optionsfilters:any, arrayfilters:any) => {
        if(optionsfilters == undefined)
            return `Fallo en la carga de los filtros, vuelva a refrescar la página.`;

        let filters = fields_filters.map((item:any, index:number)=>{

            if(item.type == 1)
                return (
                    <SelectorField
                        key={index}
                        name={item.name}
                        id={item.id}
                        deviceData={arrayfilters}
                        fieldsData={optionsfilters[item.id]?optionsfilters[item.id]:[]}
                        handleFieldChange={this.handleChangeFilter}
                        required={item.required}
                        style={item.style}
                        className='filter-item'
                    />
                );
        })

        return filters;
    }
    render() {

        const {loadingDocuments, options, documents, searchValue, creatingItem, bRegister, filter_id} = this.state;
        const {me, filters} = this.props;
        const empresa:ICentroTrabajo|undefined = GetEmpresa(this.props.match.params.centrotrabajoId, me);

        let title = (
            <div className='App-title-webPage'>
                {`Panel puestos de trabajo`}
                {'  '}
                {/*<Loader loading={this.state.loading} />*/}
            </div>
        );

        const apply_filters: any = filters[filter_id];
        const f_documents = this.handleFilterDocuments(documents, apply_filters);

        const optionsRow: IOptionRow[] = [
            { id: 1, numeric: true, disablePadding: false, label: 'Editar registro', enabled: (me && [50,100].includes(GetRole(me, this.props.match.params.centrotrabajoId))?true:false) },
            { id: 2, numeric: true, disablePadding: false, label: 'Editar EPIs', enabled: (me && [20,50,100].includes(GetRole(me, this.props.match.params.centrotrabajoId))?true:false) },
        ];

        
        return (
            <div className='App-basic'>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa
                    showLateralMenu
                />
                <div className='App-body-webBar'>
                    {me?
                        [20,50,100].indexOf(GetRole(me, this.props.match.params.centrotrabajoId)) == -1?
                            <div>
                                <div className="App-header">
                                    {title}
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {title}
                                    <div className='filter-list' style={{ marginTop:20 }}>                                                        
                                        <Toolbar variant="dense" className='filter-list-toolbar'>
                                            {this.RenderFilters(options_filters, apply_filters)}
                                        </Toolbar>
                                        <div className='clear-filter'>
                                            {Object.keys(apply_filters).length == 0?
                                                <IconButton aria-label="filter list">
                                                    <FilterListIcon />
                                                </IconButton>
                                            :
                                                <IconButton onClick={(evt:any)=>this.handleCleanFilters(evt)} aria-label="filter list">
                                                    <ClearAllIcon />
                                                </IconButton>
                                            }
                                        </div>                                        
                                    </div> 
                                    <div className="App-header-options">
                                        <div style={{ display:'flex', gap:'16px' }}>
                                            {[50,100].includes(GetRole(me, this.props.match.params.centrotrabajoId))?
                                            <div className={`App-header-options-item App-header-options-item-create ${!loadingDocuments?null:`App-header-options-item-disable`}`} onClick={()=>(!loadingDocuments?this.handleClickListado(1):null)}>
                                                {`+ Crear puesto trabajo`}
                                            </div>
                                            :
                                                null
                                            }
                                            <div className="App-header-options-item">
                                                <IconButton onClick={()=>this.handleDownloadList(f_documents)} aria-label="download list" style={{marginLeft:10}}>
                                                    <DownloadIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                            <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                                    </div>
                                </div>
                                <div>
                                    {this.state.editing?
                                            <Button disabled={this.state.saving?true:false} onClick={()=>this.handleSaveData(documents)} variant="contained" color="primary" style={{backgroundColor:'#8cd98c', width:'100%', marginTop:20}}>
                                                {!this.state.saving?
                                                    <TakeIcon style={listadoStyles.leftIcon}/>
                                                :
                                                    <Loader loading={true}/>
                                                }
                                                {`${this.state.saving?`Guardando...`:`Guardar`}`}
                                            </Button>
                                    :
                                        null
                                    }
                                </div>
                                <div>
                                    <PaginatedTable
                                        data={f_documents}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}
                                        handleItemClick={this.handleItemClick}
                                        handleClickOptionRow={this.handleClickOptionRow}
                                        handleChangeCheckBox={this.handleChangeCheckBox}
                                        handleDownloadList={this.handleDownloadList}
                                        optionsRow={optionsRow}
                                    />
                                </div>
                                {this.state.creationItemOpen?
                                    <FormListado
                                        options={options}
                                        handleCloseItemForm={this.onCloseFormItemModal}
                                        handleCreateItem={this.handleCreateItem}
                                        loading={creatingItem}
                                        register={bRegister}
                                    />
                                :
                                    null
                                }
                                {this.state.creationEpisOpen?
                                    <FormSettingEpis
                                        options={options}
                                        handleCloseItemForm={this.onCloseFormEpisItemModal}
                                        handleCreateItem={this.handleCreateEpisItem}
                                        loading={creatingItem}
                                        register={bRegister}
                                    />
                                :
                                    null
                                }
                                {this.state.creationModalOpen?
                                    <CreateModal
                                        onCloseCreationModal={this.onCloseCreationModal}
                                        isOpen={this.state.creationModalOpen}
                                        inputText={this.state.modalText}
                                        typeModal={this.state.modalType}
                                        action={this.handleCreateModal}
                                        typeAction={this.state.modalAction}
                                        loadingModal={this.state.loadingModal}
                                        titleModal={this.state.titleModal}
                                    />
                                :
                                    null
                                }
                            </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }
                    
                    {this.state.downloaddata && empresa?
                        exportDataExcel(empresa, filter(f_documents, function(o){return o.used}))
                    :
                        null
                    }

                    <LoaderBeauty visible={loadingDocuments?true:false}/>
                </div> 
            </div>
        );
    }
}

export default Income;